import React from "react";
import { Head } from "../../Head";
import Layout from "../../Layout";
import Section from "../../Section";
import { SharedHero } from "../../shared/SharedHero";
import { Menu, TPages } from "./Menu";

type IProps = {
  page: TPages;
  title?: string;
};

const sectionColor = "#fff";

export const sectionProps = {
  bg: sectionColor,
  px: 6,
  py: 10,
};

export const SectionLayout: React.FC<IProps> = ({
  children,
  page,
  title = "",
}) => {
  return (
    <Layout>
      <Head title={`HCBE | ${title}`} />

      <SharedHero />

      <Menu page={page} />

      <Section bg={"utils.white"} containerProps={{ py: "2rem" }}>
        {children}
      </Section>
    </Layout>
  );
};
