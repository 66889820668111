import {
  Box,
  Heading,
  Link,
  ListItem,
  Stack,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import React from "react";
import {
  SectionLayout,
  sectionProps,
} from "../../../components/page-sections/info-per-il-pubblico/layout";

const ReclamiPage: React.FC = () => {
  return (
    <SectionLayout page="reclami" title="Reclami">
      <Box {...sectionProps}>
        <Stack spacing={4} direction={"column"} textAlign={"justify"}>
          <Heading
            as={"h1"}
            fontSize={["24px", "32px"]}
            fontWeight={"400"}
            textAlign={"left"}
            fontFamily={"body"}
            color={"accent.01"}
          >
            Reclami
          </Heading>
          <Heading as={"h5"} size="md" fontFamily={"body"} color={"accent.01"}>
            Vuoi sporgere un reclamo?
          </Heading>
          <Text>
            Il Cliente può presentare un reclamo esclusivamente in forma
            scritta. <br />
            Il reclamo può essere trasmesso all'Ufficio Reclami tramite:
          </Text>
          <UnorderedList pl={5}>
            <ListItem>
              posta cartacea indirizzata a:
              <Box ml={6}>
                HCBE. Ufficio Reclami <br />
                Corso Massimo D'Azeglio, 33/E <br />
                10126 Torino
              </Box>
            </ListItem>
            <ListItem>
              posta elettronica indirizzata a{" "}
              <Link
                color="accent.01"
                href="mailto:reclami@it.hcs.com"
                isExternal
              >
                reclami@it.hcs.com
              </Link>
            </ListItem>
            <ListItem>
              posta elettronica certificata indirizzata a{" "}
              <Link
                color="accent.01"
                href="mailto:a  reclami.hcbeitaly@actaliscertymail.it"
                isExternal
              >
                reclami.hcbeitaly@actaliscertymail.it
              </Link>
            </ListItem>
          </UnorderedList>
          <Text>
            L’intermediario deve rispondere ai reclami entro 60 giorni dalla
            ricezione se relativi a prodotti bancari e finanziari, entro 45
            giorni se riferiti a prodotti assicurativi ed entro 30 giorni se
            relativi al trattamento dei dati personali. Quest’ultimo termine può
            essere prorogato a 60 giorni, qualora si renda necessario tenuto
            conto della complessità e del numero di richieste. In tal caso,
            l’intermediario provvede a darne comunicazione all'interessato entro
            30 giorni dal ricevimento del reclamo.
          </Text>
          <Text>
            Se il reclamo ha ad oggetto i servizi di pagamento di cui alla
            direttiva PSD2 il riscontro deve essere fornito in 15 giornate
            lavorative. Qualora per motivi indipendenti dalla sua volontà, in
            situazioni eccezionali, l’intermediario non possa rispondere entro
            il predetto termine, è tenuto ad inviare una risposta interlocutoria
            indicando chiaramente le ragioni del ritardo e specificando il
            termine entro il quale il cliente otterrà una risposta definitiva.
            In ogni caso il termine per la ricezione della risposta definitiva
            non supera le 35 giornate lavorative, ovvero il diverso termine
            tempo per tempo vigente.
          </Text>
          <Text>
            I canali che possono essere utilizzati per la trasmissione dei
            riscontri sono la posta elettronica certificata, la posta
            elettronica, il fax e la posta cartacea.
          </Text>
          <Text>
            Se non è soddisfatto o non ha ricevuto risposta, prima di ricorrere
            al giudice, il cliente può rivolgersi all'Arbitro Bancario
            Finanziario (ABF), che è un sistema stragiudiziale di risoluzione
            delle controversie tra i clienti e il mondo bancario. Per sapere
            come rivolgersi all'Arbitro si può consultare il sito{" "}
            <Link
              color="accent.01"
              href={"https://www.arbitrobancariofinanziario.it"}
              isExternal
            >
              www.arbitrobancariofinanziario.it
            </Link>
            , chiedere presso le Filiali della Banca d'Italia, oppure chiedere a
            HCBE.
          </Text>
          <Text>
            Per informazioni di carattere generale sull'Arbitro Bancario
            Finanziario (ad esempio su chi può ricorrere, dove trovare il modulo
            di ricorso, come presentare il ricorso) telefonare al numero verde{" "}
            <Link color="accent.01" href="tel:800196969">
              800 19 69 69
            </Link>
            .
          </Text>
          <Text>
            Per poter utilizzare meglio questo servizio e capire come tutelare i
            propri diritti è possibile scaricare la Guida e la modulistica
            relative all'ABF:
          </Text>
          <UnorderedList pl={5}>
            {/* TODO: Mancano file PDF: vedi https://www.santanderconsumer.it/footer/info-per-il-pubblico/reclami */}
            <ListItem>
              <Link
                color="accent.01"
                href="/documents/informazioni-per-il-pubblico/Guida-ricorso-abf.pdf"
                isExternal
              >
                Guida ricorso ABF
              </Link>
            </ListItem>
            <ListItem>
              <Link
                color="accent.01"
                href="/documents/informazioni-per-il-pubblico/Istruzioni-compilazione-ricorso.pdf"
                isExternal
              >
                Istruzioni compilazione ricorso
              </Link>
            </ListItem>
            <ListItem>
              <Link
                color="accent.01"
                href="/documents/informazioni-per-il-pubblico/Modulo_di_ricorso_ABF.pdf"
                isExternal
              >
                Modulo di ricorso
              </Link>
            </ListItem>
          </UnorderedList>
          <Text>
            Per visualizzare il rendiconto sull'attività di gestione dei reclami
            ricevuti tra 1° gennaio 2022 ed il 31 dicembre 2022 svolta da HCBE{" "}
            <Link
              color="accent.01"
              href="/documents/informazioni-per-il-pubblico/20230424_Rendiconto attività reclami 2023 HCBE_DEF.pdf"
              isExternal
            >
              clicca qui.
            </Link>
          </Text>
        </Stack>
      </Box>
    </SectionLayout>
  );
};

export default ReclamiPage;
